
import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import tableCheck from "@/mixins/table-check";
import TableEnableFlag from "@/components/scope/table-enable-flag.vue";
import { emptyToLine } from "@/utils/common";
import { Pageniation, Loading } from "@/utils/decorator";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";

const banner = namespace("banner");
const base = namespace("base");
const consult = namespace("consult");

@Component({
  components: { TableEnableFlag }
})
@Pageniation("loadListData")
export default class BannerList extends Mixins(tableCheck) {
  @base.Action getDictionaryList;
  @banner.Action getbannerList;
  @banner.Action endbanner;
  @banner.Action pushbanner;
  @consult.Action queryUserAccountList;

  dayjs = dayjs;

  searchForm = {
    title: "", //Banner名称
    bannerCode: "", //Banner编号
    status: "",
    beginTime: "",
    endTime: "",
    bannerRange: "", //发布范围
    publisher: "" //发布人
  };
  dialogVisible: boolean = false;
  emptyToLine = emptyToLine;
  bannerList: any[] = [];
  storeTypeList: any[] = [];
  appTypeList: any[] = [];
  publisherList: any = [];

  get searchList() {
    return [
      {
        label: t("v210831.banner-name-not-flag"),
        type: "input",
        value: "",
        prop: "bannerName"
      },
      {
        label: t("v210831.banner-number"),
        type: "input",
        value: "",
        prop: "bannerCode"
      },
      {
        label: t("v210801.state"),
        type: "select",
        selectList: this.statusList,
        value: "",
        prop: "status"
      },
      {
        label: t("v210831.release-date"),
        type: "daterange",
        value: "",
        prop: "Time"
      },
      {
        label: t("v210831.release-scope"),
        type: "select",
        selectList: this.storeTypeList,
        value: "",
        prop: "bannerRange"
      },
      {
        label: t("v210831.publisher"),
        type: "select",
        selectList: this.publisherList,
        value: "",
        prop: "publisher"
      }
    ];
  }
  handleView(row) {
    window.open(`${row.bannerUrl}`, "_blank");
    console.log(row, "???");
  }
  //多行文本省略
  filters(value) {
    if (!value) return "";
    if (value.length > 20) {
      return value.slice(0, 20) + "...";
    }
    return value;
  }
  //发布banner
  pushbanneritem(pushdata) {
    this.pushbanner(pushdata).then(() => {
      this.loadListData();
      this.$message.success(t("v210831.published-successfully!"));
    });
  }
  //下架banner
  handlefinished(finisheddata) {
    console.log(finisheddata, "finisheddata");
    this.endbanner(finisheddata).then(data => {
      if (data.success === true) {
        this.loadListData();
      } else {
        this.$message.error(t("v210831.failed-to-get"));
      }
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "Time") {
          this.searchForm.beginTime = item.value ? item.value[0] : "";
          this.searchForm.endTime = item.value ? item.value[1] : "";
        }
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  created() {
    this.init();
  }
  @Loading()
  loadListData() {
    return this.getbannerList({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.bannerList = data.data.list;
      return data;
    });
  }
  async init() {
    this.loadListData();
    this.getDictionaryList("banner_range").then(data => {
      console.log(data, "appList");
      this.storeTypeList = data;
    });
    this.getUserAccount();
  }
  get statusList() {
    return [
      {
        label: t("v210831.saved"),
        value: 0
      },
      {
        label: t("v210831.published"),
        value: 1
      },
      {
        label: t("v210831.removed"),
        value: 2
      }
    ];
  }
  getUserAccount() {
    this.queryUserAccountList({
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.publisherList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.firstName;
            item.value = item.firstName;
            return item;
          })
        : [];
    });
  }
  handleEdit(row) {
    this.$router.push(`/management/banner-edit/${row.bannerCode}`);
  }
  handleCommand(Command) {
    if (Command === "edit") {
      this.$router.push(`/management/banner-edit/${this.row.bannerCode}`);
    } else if (Command === "copy") {
      this.$router.push(`/management/banner-add?code=${this.row.bannerCode}`);
    }
  }
  row: any = {};
  handleRow($vent, row) {
    this.row = row;
    console.log("[ row ]", row);
  }
  // ////
}
